import React from "react";
import {createRoot} from 'react-dom/client';
import './i18n';
import './yupLocalization';
import AppContainer from "./components/AppContainer";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./routes";

const router = createBrowserRouter(routes, {future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionStatusRevalidation: true,
    v7_skipActionErrorRevalidation: true,
}});

const container = document.querySelector('#root')
const root = createRoot(container)

root.render(
    <React.StrictMode>
        <AppContainer>
            <RouterProvider router={router}
                future={{
                    v7_startTransition: true,
                    v7_relativeSplatPath: true,
                }}
            />
        </AppContainer>
    </React.StrictMode>
);
