import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider, useMediaQuery } from "@mui/material";
import StartToolbar from "./StartToolbar";
import i18n from "i18next";

const queryClient = new QueryClient();

const AppContainer = ({ children }) => {
    const searchParams = new URLSearchParams(document.location.search);
    const showBanner = !(searchParams.get("showbanner")?.toLowerCase() === "false");
    const locale = searchParams.get("locale")?.toLowerCase() || "de";
    const [mode, setMode] = React.useState("light");
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

    useEffect(() => {
        setMode(prefersDarkMode ? "dark" : "light");
    }, [prefersDarkMode]);

    useEffect(() => {
        if (!showBanner) {
            i18n.changeLanguage(locale).then();
        }
    }, [locale, showBanner]);

    const theme = React.useMemo(() => {
        const newTheme = createTheme({
            palette: {
                mode,
            },
        });
        return responsiveFontSizes(newTheme);
    }, [mode]);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {showBanner ? (
                    <StartToolbar mode={mode} onToggleMode={() => setMode((m) => (m === "dark" ? "light" : "dark"))} />
                ) : null}
                {children}
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default AppContainer;
